import React from 'react'
import { graphql, Link } from 'gatsby'
import { Heading, Text, Box, SimpleGrid } from '@pw/ui'

import { Layout, Container } from '../components/Layout'
import PostGrid from '../components/Posts/Grid'
import ContentBox from '../components/ContentBox'

export const CategoryDestinationTemplate = ({ data, location }) => {
  const { name, seo = {} } = data?.contentfulDestination || {}
  const destinations = data?.allContentfulDestination?.edges || []

  return (
    <Layout
      headerTitle={name}
      location={location}
      seo={{
        ...seo,
        title: seo?.title || `${name} Wellness Travel`,
        description: seo?.description || `Wellness Travel posts related to ${name}`,
      }}
    >
      <Container pt="4" py="8" px={{ base: 0, md: '8' }} maxW="1200px">
        <SimpleGrid spacing="10" columns="4">
          {destinations
            .filter(({ node: { name } }) => Boolean(name))
            .map(({ node }) => (
              <ContentBox
                key={node.id}
                title={node.name}
                as={Link}
                to={`/${node.slug}`}
              />
            ))}
        </SimpleGrid>
      </Container>
    </Layout>
  )
}

export default CategoryDestinationTemplate

export const pageQuery = graphql`
  query CategoryDestinations {
    allContentfulDestination {
      edges {
        node {
          id
          slug
          name
        }
      }
    }
  }
`
